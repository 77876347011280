import {
  RadioItem,
  Wrapper,
} from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const AboutYourPetSection = styled.div`
  margin-top: ${spacing(4)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(6)};
  `}
`;

export const StyledQuestionField = styled(QuestionField)`
  margin-bottom: ${spacing(0)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(0)};
  `}
  ${Wrapper} {
    display: flex;
    flex-direction: column;
    ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
    ${RadioItem}:not(:first-of-type) {
      margin: ${spacing(2)} 0 0 0;
      ${mediaQuery.tabletPortrait`
      margin: 0 0 0 ${spacing(4)};
    `}
    }
  }
`;

export const StyledQuestionFieldWithMargin = styled(StyledQuestionField)`
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(2)};  
    margin-bottom: ${spacing(4)};
  `}
`;

export const StyledPanel = styled.div`
  background-color: ${colors.neutral08};
  border: 1px ${colors.core01} solid;
  padding: ${spacing(3)};
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)};
    `};
  margin-bottom: ${spacing(2)};
  display: flex;
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
  align-self: flex-start;
  ${mediaQuery.tabletPortrait`
    align-self: center;
    `};
`;

export const StyledRichText = styled(RichText)`
  ${fonts.paragraphLarge}
  margin-right: auto;
  padding-bottom: ${spacing(3)};
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${colors.neutral04};

  margin-bottom: ${spacing(2)};
  svg {
    fill: ${colors.core03};
  }
`;

export const StyledRichTextWithModal = styled(RichTextWithModal)`
  ${fonts.paragraphLarge}
  margin-right: auto;
  padding-bottom: ${spacing(3)};
  border: 0;
  border-bottom: 1px;
  border-style: solid;
  border-color: ${colors.neutral04};

  margin-bottom: ${spacing(2)};
  svg {
    fill: ${colors.core03};
  }
`;

export const StyledPanelHeadingContainer = styled.div`
  display: flex;
`;

export const StyledPanelHeading = styled.p`
  ${fonts.headingXSmall}
  margin: 0;
`;
