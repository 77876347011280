import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import { fonts } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const ImportantInformation = styled(RichTextWithModal)`
  ${RichText} {
    ${mediaQuery.tabletLandscape`
      p {
        ${fonts.paragraphLarge}
      }
    `}
  }
`;
