import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';
import BannerWithAddButton from 'forms/BannerWithAddButton';

export const StyledBannerWithAddButton = styled(BannerWithAddButton)`
  margin-top: ${spacing(4)};
`;

export const StyledBannerSection = styled(BannerWithAddButton)`
  margin-top: ${spacing(4)};
`;

export const FirstSectionSubheading = styled(RichTextWithModal)`
  margin: ${spacing(4)} 0 0;

  ${mediaQuery.tabletLandscape`
    p {
      ${fonts.paragraphLarge}
    }
  `}
`;
