import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled, { css } from 'styled-components';

export const StyledTextInput = styled(TextInput)`
  &::before {
    ${fonts.paragraphLarge};
    & {
      font-weight: ${semiBoldFontWeight};
    }
  }

  input {
    ${({ prefix }) =>
      prefix &&
      css`
        padding-left: ${spacing(4)};
      `}
  }

  margin-bottom: ${spacing(2)};
`;
