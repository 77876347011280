import {
  addYearsToDate,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { JointPolicyholderDetails } from 'state/formData/jointPolicyholderDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsJointPolicyholderErrorMessages = {
  csPetJointPolicyholderQuestions: {
    joint_policyholder_title: CsErrorsMissingOnly;
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetJointPolicyholderQuestions {
      joint_policyholder_title {
        error_messages {
          missing
        }
      }
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
    }
  }
`;

const useJointPolicyholderRules = (): ValidationRules<JointPolicyholderDetails> => {
  const errorMessages = useStaticQuery<CsJointPolicyholderErrorMessages>(query);

  return {
    jointPolicyholderTitle: [
      required(
        errorMessages.csPetJointPolicyholderQuestions.joint_policyholder_title
          .error_messages.missing
      ),
    ],
    jointPolicyholderFirstName: [
      required(
        errorMessages.csPetJointPolicyholderQuestions.first_name.error_messages.missing
      ),
    ],
    jointPolicyholderLastName: [
      required(
        errorMessages.csPetJointPolicyholderQuestions.last_name.error_messages.missing
      ),
    ],
    jointPolicyholderDob: [
      requiredDateValue(
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages.missing
      ),
      dateValueValidDay(
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .invalid_day
      ),
      dateValueValidMonth(
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .invalid_month
      ),
      dateValueValid(
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .invalid_date
      ),
      dateValueValidYear(
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .year_too_short
      ),
      dateValueLessThanOrEqualTo(
        new Date(),
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .date_in_future
      ),
      dateValueLessThanOrEqualTo(
        addYearsToDate(new Date(), -18),
        errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
          .too_young
      ),
      {
        validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
        errorMessage:
          errorMessages.csPetJointPolicyholderQuestions.date_of_birth.error_messages
            .invalid_date,
      },
    ],
  };
};

export default useJointPolicyholderRules;
