import { TooltipBody } from '@rsa-digital/evo-shared-components/components/Form/Field/FieldInfo/Tooltip/styles';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import QuestionField from 'components/QuestionField';

export const CatBreedTypeQuestionField = styled(QuestionField)`
  ${TooltipBody} {
    p + p {
      margin-top: ${spacing(2)};
    }
  }
`;
