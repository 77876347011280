import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, navigate } from 'gatsby';
import React, { useEffect } from 'react';
import LoadQuoteInProgressWrapper from 'components/ApiRequestWrapper/LoadQuoteInProgressWrapper';
import Layout from 'components/Layout';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import AboutYouAndYourPetForm from 'forms/AboutYouAndYourPetForm';
import { PageTitle } from 'helpers/eventTracking';
import { getQueryParam } from 'helpers/getQueryParam';
import { usePageTracking } from 'helpers/pageTracking';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { SOURCE_CODE_SESSION_KEY, storeData } from 'helpers/sessionStorageHelpers';
import { usePolicyDetails } from 'state/formData/policyDetails';
import { CsHero } from 'types/contentStack';
import { ImportantInformation } from './styles';

type AboutYouAndYourPetProps = {
  data: {
    csPetAboutYouAndYourPet: {
      meta_title: string;
      hero: CsHero;
      important_information: string;
    };
  };
  location: Location;
};

export const query = graphql`
  query {
    csPetAboutYouAndYourPet {
      meta_title
      hero {
        heading
        subheading
      }
      important_information
    }
  }
`;

const AboutYouAndYourPet: React.FC<AboutYouAndYourPetProps> = ({
  data: {
    csPetAboutYouAndYourPet: { important_information, hero, meta_title },
  },
  location,
}) => {
  const moveNext = (): void => {
    navigate(quoteAndBuyRoutes.loadingQuote);
  };

  const [, updatePolicyDetails] = usePolicyDetails();
  usePageTracking(meta_title);

  const sourceCode = getQueryParam(location, 'brand') ?? undefined;

  useEffect(() => {
    if (sourceCode) {
      storeData(SOURCE_CODE_SESSION_KEY, sourceCode);
      updatePolicyDetails({ referrerId: sourceCode });
    }
  }, [sourceCode, updatePolicyDetails]);

  return (
    <LoadQuoteInProgressWrapper>
      <Layout
        pageTitle={PageTitle.AboutYouAndYourPet}
        currentStep={QuoteAndBuyStep.AboutYouAndYourPet}
        heading={hero.heading}
        subheading={hero.subheading}
        metaTitle={meta_title}>
        <Grid alignLeft>
          <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
            <ImportantInformation
              pageTitle={PageTitle.AboutYouAndYourPet}
              data-cy="important_information"
              html={important_information}
            />
          </GridItem>
        </Grid>
        <AboutYouAndYourPetForm moveNext={moveNext} />
      </Layout>
    </LoadQuoteInProgressWrapper>
  );
};

export default AboutYouAndYourPet;
