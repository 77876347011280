import React from 'react';
import CoverStartDate from 'components/CoverStartDate';
import SectionHeading from 'forms/SectionHeading';
import { PageTitle } from 'helpers/eventTracking';
import useAboutYourPolicyQuestions from './questions';

const AboutYourPolicyForm: React.FC = () => {
  const questions = useAboutYourPolicyQuestions();
  const aboutYourPolicySectionId = 'about-your-policy-section';

  return (
    <>
      <SectionHeading
        heading={questions.coverStartDate.questionText}
        id={aboutYourPolicySectionId}
      />
      <CoverStartDate pageTitle={PageTitle.CoverStartDate} />
    </>
  );
};

export default AboutYourPolicyForm;
