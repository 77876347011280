import RepeatableSection from '@rsa-digital/evo-shared-components/components/Form/RepeatableSection';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import ScreenReaderAnnouncer, {
  useScreenReaderAnnouncement,
} from '@rsa-digital/evo-shared-components/components/ScreenReaderAnnouncer';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { navigate } from 'gatsby';
import React from 'react';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { addItem, removeItem, updateItem } from 'helpers/repeatableForm';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import AboutYourPetForm from './AboutYourPet';
import {
  FirstSectionSubheading,
  StyledBannerSection,
  StyledBannerWithAddButton,
} from './styles';
import { usePetDetailsOptions } from './usePetsDetailsOptions';

type AboutYourPetsFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
    resetValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYourPetsForm: React.FC<AboutYourPetsFormProps> = ({ formValidation }) => {
  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const {
    defaultSectionHeadings,
    removePetButtonTexts,
    addPetButtonText,
    addPetBanner,
    petSectionHeading,
    petSectionSubheading,
    addPetBannerHelpText,
    threePetsAddedBanner,
  } = usePetDetailsOptions();

  const generateSectionLabel = (index: number): string => {
    const { petName } = petsDetails[index];
    return petName
      ? petNameReplacer(petName, petSectionHeading)
      : defaultSectionHeadings[index];
  };

  const [announcementText, makeAnnouncement] = useScreenReaderAnnouncement();

  const showAddPet: boolean = petsDetails.some(
    ({ petType, petName, petIsEligible, petInGoodHealth, petLivesWithYou }) =>
      (petType === petType_DOG && petInGoodHealth && petIsEligible && petName) ||
      (petType === petType_CAT && petInGoodHealth && petLivesWithYou && petName)
  );

  const showThreePetsBanner =
    petsDetails?.length === 3
      ? (petsDetails[2].petType === petType_DOG &&
          petsDetails[2].petInGoodHealth &&
          petsDetails[2].petIsEligible &&
          petsDetails[2].petName &&
          petsDetails[2].petSpayed) ||
        (petsDetails[2].petType === petType_CAT &&
          petsDetails[2].petInGoodHealth &&
          petsDetails[2].petLivesWithYou &&
          petsDetails[2].petName &&
          petsDetails[2].petSpayed)
      : false;

  return (
    <>
      <ScreenReaderAnnouncer announcementText={announcementText} />
      <RepeatableSection
        onAdd={() => {
          trackTextButtonClick(PageTitle.AboutYouAndYourPet, addPetButtonText);
          updatePetsDetails(addItem(petsDetails, initialPet));
        }}
        onRemove={(index) => {
          trackTextButtonClick(PageTitle.AboutYouAndYourPet, removePetButtonTexts[index]);
          const currentPets = petsDetails;
          const updatedPets = removeItem(currentPets, index);
          updatePetsDetails(updatedPets);
          makeAnnouncement(`Pet ${index + 1} has been successfully removed`);
          navigate('#add-pet-banner');
        }}
        // We disable the add button and use the alternate 'add pet banner' instead of the default button
        disableAdd
        label={generateSectionLabel}
        addText={addPetButtonText}
        removeText={(index) => removePetButtonTexts[index]}
        alignLeft>
        {petsDetails.map((pet, index) => (
          <>
            <Grid alignLeft>
              <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                {index === 0 && (
                  <FirstSectionSubheading
                    html={petSectionSubheading}
                    pageTitle={PageTitle.AboutYouAndYourPet}
                  />
                )}
                <AboutYourPetForm
                  key={pet.key}
                  index={index}
                  petDetails={pet}
                  updatePetDetails={(update) =>
                    updatePetsDetails(updateItem(petsDetails, index, update))
                  }
                  formValidation={formValidation}
                />
              </GridItem>
            </Grid>
          </>
        ))}
      </RepeatableSection>

      {showAddPet && petsDetails.length < 3 && (
        <StyledBannerWithAddButton
          data-cy="addPetBanner"
          id="add-pet-banner"
          pageTitle={PageTitle.AboutYouAndYourPet}
          headingText={addPetBanner.headingText}
          bodyText={addPetBanner.bodyText}
          buttonText={addPetBanner.buttonText}
          buttonAriaLabel={addPetBanner.buttonAriaLabel}
          buttonId="addPetButton"
          helpText={addPetBannerHelpText}
          gridItemProps={{
            desktop: 6,
            tabletLandscape: 6,
            tabletPortrait: 6,
          }}
          addFormSectionButtonClick={() => {
            updatePetsDetails(addItem(petsDetails, initialPet));
            /* This use of setTimeout forces scrollAndFocusInput to be called after
             * updatePetsDetails, so that we attempt to scroll to the newly added pet
             * form section *after* the section has been rendered in the DOM.
             *
             * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
             * details about this implementation.
             */
            setTimeout(() => scrollAndFocusInput(`petDetails[${petsDetails.length}]`));
            trackTextButtonClick(PageTitle.AboutYouAndYourPet, 'Add a pet - pet details');
          }}
          icon={addPetBanner.icon}
        />
      )}

      {showThreePetsBanner && petsDetails.length === 3 && (
        <StyledBannerSection
          data-cy="threePetsAddedBanner"
          pageTitle={PageTitle.AboutYouAndYourPet}
          headingText={threePetsAddedBanner.bannerHeadingText}
          bodyText={threePetsAddedBanner.bannerBodyText}
          gridItemProps={{
            desktop: 6,
            tabletLandscape: 6,
            tabletPortrait: 6,
          }}
          icon={threePetsAddedBanner.bannerIcon}
        />
      )}
    </>
  );
};

export default AboutYourPetsForm;
