import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { AssumptionId } from 'businessLogic/aggregatorAssumptions';
import { useEffect } from 'react';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { usePetsDetails } from 'state/formData/petsDetails';
import { FormDetails } from './types';

export const useDisplayAssumptionWarningsOnRender = (
  showValidation: FieldFunction<FormDetails, void>
): void => {
  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const [petDetails] = usePetsDetails();

  const assumptionIdToFormFieldValidationMapping: Record<AssumptionId, () => void> = {
    good_health: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['petInGoodHealth', i]);
      });
    },
    no_complaints_about_behaviour: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['dogComplaints', i]);
      });
    },
    not_involved_in_legal_action: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['dogLegalAction', i]);
      });
    },
    pet_lives_with_you: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['petLivesWithYou', i]);
      });
    },
    eligibility_question: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['petIsEligible', i]);
      });
    },
    pet_for_make_money: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['petForMakeMoney', i]);
      });
    },
    dog_breed_list_link: () => {
      petDetails.forEach((_, i) => {
        showValidation('petDetails', ['dogBreedListLink', i]);
      });
    },
    one_pet_in_household: () => showValidation('numberOfPetsInHousehold'),
    two_pets_in_household: () => showValidation('numberOfPetsInHousehold'),
    three_pets_in_household: () => showValidation('numberOfPetsInHousehold'),
  };

  useEffect(() => {
    if (assumptions !== undefined && !assumptionsAgreement.assumptionsAgreed) {
      assumptions.assumptions.forEach(({ id }) =>
        assumptionIdToFormFieldValidationMapping[id]()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
