import AddButton from '@rsa-digital/evo-shared-components/components/AddButton';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/AddButton/styles';
import { InfoPanel } from '@rsa-digital/evo-shared-components/components/Panel/StatusPanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { semiBoldFontWeight } from '@rsa-digital/evo-shared-components/theme/MarksAndSpencer/fonts';
import styled from 'styled-components';

export const StyledInfoPanel = styled(InfoPanel)`
  &&& {
    border: solid 1px ${colors.core03};

    padding: ${spacing(2)} ${spacing(2)} ${spacing(4)} ${spacing(2)};

    ${mediaQuery.tabletPortrait`
      padding: ${spacing(4)} ${spacing(8)} ${spacing(4)} ${spacing(4)};
    `}
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const PanelIconWrapper = styled.div`
  margin-right: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-right: ${spacing(4)};
  `}
`;

export const Heading = styled.p`
  ${fonts.headingXSmall};
  & {
    font-weight: ${semiBoldFontWeight};
  }
  margin: 0 0 ${spacing(1)};
`;

export const AddButtonWithTopMargin = styled(AddButton)`
  margin-top: ${spacing(2)};

  ${IconWrapper} {
    padding-right: ${spacing(1)};
  }
`;
