import DateInput from '@rsa-digital/evo-shared-components/components/Form/DateInput';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import TextInput from '@rsa-digital/evo-shared-components/components/Form/TextInput';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { addLeadingZerosToDateValue } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import {
  FieldFunction,
  QuestionWithPlaceholderField,
  StringField,
} from '@rsa-digital/evo-shared-components/helpers/forms/types';
import React, { ReactElement } from 'react';
import { NameInput } from 'components/NameInput/styles';
import PromotionalBox from 'components/PromotionalBox';
import QuestionField from 'components/QuestionField';
import SelectInput from 'components/SelectInput';
import { FormDetails } from 'forms/AboutYouAndYourPetForm/types';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  PageTitle,
  trackFormDropdownFocus,
  trackFormDropdownSelect,
  trackFormTextFieldFocus,
  trackRadioButtonClick,
  trackTextButtonClick,
} from 'helpers/eventTracking';
import { hasGenderlessTitle } from 'helpers/genderHelpers';
import { INPUT_REGEX_NAME, INPUT_REGEX_NAME_SPECIAL_CHARS } from 'helpers/inputRegexes';
import { addItem } from 'helpers/repeatableForm';
import { capitaliseCharacterAfterHyphenAndSpace } from 'helpers/stringHelpers';
import { useTouchAssumption } from 'state/formData/assumptionsAgreement';
import { CustomerDetails, useCustomerDetails } from 'state/formData/customerDetails';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import useReferenceData from 'state/referenceData/useReferenceData';
import { ReferenceDataOption } from 'types/referenceData';
import AddressForm from './AddressForm';
import { filterTitleOptions } from './filterTitleOptions';
import useAboutYouQuestions, { AboutYouQuestions } from './questions';
import { QuestionFieldWithMarginBottom } from './styles';

type AboutYouFormProps = {
  formValidation: {
    getError: FieldFunction<FormDetails, string | undefined>;
    getWarning: FieldFunction<FormDetails, string | undefined>;
    showValidation: FieldFunction<FormDetails, void>;
  };
};

const AboutYouForm: React.FC<AboutYouFormProps> = ({ formValidation }) => {
  const { getError, getWarning, showValidation } = formValidation;
  const questions = useAboutYouQuestions();
  const [customerDetails, updateCustomerDetails] = useCustomerDetails();
  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const touchAssumption = useTouchAssumption();

  const numberOfPetsInQuote = petsDetails.length;

  const gendersRefData = useReferenceData('genders');
  const titlesRefData = useReferenceData('titles')?.titles ?? [];
  const filteredTitlesRefData = filterTitleOptions(
    titlesRefData,
    customerDetails.customerTitle
  );

  const renderRefDataQuestion = (
    id: QuestionWithPlaceholderField<AboutYouQuestions> & StringField<CustomerDetails>,
    options: ReferenceDataOption[] | undefined,
    analyticsDescription?: string
  ): ReactElement => (
    <QuestionField
      question={questions[id]}
      errorText={getError(id)}
      warningText={getWarning(id)}>
      <SelectInput
        id={id}
        value={customerDetails[id]}
        options={options ?? []}
        placeholder={questions[id].placeholder}
        onChange={(e) => {
          trackFormDropdownSelect(analyticsDescription || id);
          updateCustomerDetails({ [id]: e.target.value });
        }}
        onBlur={() => showValidation(id)}
        onFocus={trackFormDropdownFocus(
          analyticsDescription || id,
          PageTitle.AboutYouAndYourPet
        )}
        loading={!options}
      />
    </QuestionField>
  );

  return (
    <>
      {renderRefDataQuestion('customerTitle', filteredTitlesRefData, 'Customer title')}
      <QuestionField
        question={questions.customerFirstName}
        errorText={getError('customerFirstName')}>
        <NameInput
          id="customerFirstName"
          placeholder={questions.customerFirstName.placeholder}
          maxLength={40}
          value={customerDetails.customerFirstName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerFirstName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerFirstName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerFirstName');
          }}
          onFocus={trackFormTextFieldFocus('Customer first name')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerLastName}
        errorText={getError('customerLastName')}>
        <NameInput
          id="customerLastName"
          placeholder={questions.customerLastName.placeholder}
          maxLength={30}
          value={customerDetails.customerLastName}
          onChange={(e) => {
            if (
              e.target.value.match(INPUT_REGEX_NAME) ||
              // Users from aggs could have special characters in their name.
              // The below allows them to edit their name if required, while restricting them only to the aggs allowed special characters.
              (!customerDetails.customerLastName.match(INPUT_REGEX_NAME) &&
                e.target.value.match(INPUT_REGEX_NAME_SPECIAL_CHARS))
            ) {
              updateCustomerDetails({
                customerLastName: capitaliseCharacterAfterHyphenAndSpace(e.target.value),
              });
            }
          }}
          onBlur={() => {
            showValidation('customerLastName');
          }}
          onFocus={trackFormTextFieldFocus('Customer last name')}
        />
      </QuestionField>
      {hasGenderlessTitle(customerDetails) && (
        <QuestionField
          question={questions.customerGender}
          errorText={getError('customerGender')}>
          <RadioInput
            id="customerGender"
            value={customerDetails.customerGender}
            options={gendersRefData?.genders ?? []}
            onChange={(e) => {
              updateCustomerDetails({ customerGender: e.target.value });
              trackRadioButtonClick('Customer gender', e.target.value);
            }}
          />
        </QuestionField>
      )}
      <QuestionField question={questions.customerDob} errorText={getError('customerDob')}>
        <DateInput
          id="customerDob"
          value={customerDetails.customerDob}
          onChange={(e) =>
            updateCustomerDetails({
              customerDob: e,
            })
          }
          onBlur={() => {
            updateCustomerDetails({
              customerDob: addLeadingZerosToDateValue(customerDetails.customerDob),
            });
            showValidation('customerDob');
          }}
          onFocus={trackFormTextFieldFocus('Customer DOB')}
        />
      </QuestionField>
      <QuestionField
        question={questions.customerEmail}
        errorText={getError('customerEmail')}>
        <TextInput
          id="customerEmail"
          placeholder={questions.customerEmail.placeholder}
          maxLength={254}
          value={customerDetails.customerEmail}
          onChange={(e) => {
            updateCustomerDetails({
              customerEmail: e.target.value.replaceAll(' ', ''),
            });
          }}
          onBlur={() => showValidation('customerEmail')}
          onFocus={trackFormTextFieldFocus('Customer email')}
          inputMode="email"
        />
      </QuestionField>
      <QuestionField
        question={questions.customerTelephone}
        errorText={getError('customerTelephone')}>
        <NameInput
          id="customerTelephone"
          placeholder={questions.customerTelephone.placeholder}
          maxLength={11}
          value={customerDetails.customerTelephone}
          onChange={(e) => {
            updateCustomerDetails({
              customerTelephone: e.target.value,
            });
          }}
          numbersOnly
          onBlur={() => showValidation('customerTelephone')}
          onFocus={trackFormTextFieldFocus('Customer telephone')}
          inputMode="tel"
        />
      </QuestionField>
      <AddressForm
        addressDetails={customerDetails}
        updateAddressDetails={updateCustomerDetails}
        formValidation={formValidation}
      />
      <QuestionFieldWithMarginBottom
        question={questions.numberOfPetsInHousehold.question}
        errorText={getError('numberOfPetsInHousehold')}
        warningText={getWarning('numberOfPetsInHousehold')}>
        <SegmentedSelector
          id="numberOfPetsInHousehold"
          value={customerDetails.numberOfPetsInHousehold?.toString()}
          shownOptions={5}
          options={[
            {
              name: '1',
              value: '1',
            },
            {
              name: '2',
              value: '2',
            },
            {
              name: '3',
              value: '3',
            },
            {
              name: '4',
              value: '4',
            },
            {
              name: '5+',
              value: '5',
            },
          ]}
          onChange={(event) => {
            touchAssumption([
              'one_pet_in_household',
              'two_pets_in_household',
              'three_pets_in_household',
            ]);
            updateCustomerDetails({
              numberOfPetsInHousehold: Number(event.target.value),
            });
            trackRadioButtonClick('Number of pets in household', event.target.value);
            showValidation('numberOfPetsInHousehold');
          }}
        />
      </QuestionFieldWithMarginBottom>
      {customerDetails.numberOfPetsInHousehold &&
        numberOfPetsInQuote < 3 &&
        customerDetails.numberOfPetsInHousehold > numberOfPetsInQuote && (
          <Grid alignLeft>
            <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
              <PromotionalBox
                pageTitle={PageTitle.AboutYouAndYourPet}
                id="number-of-pets-promotional-box"
                icon={
                  unwrapSingleton(questions.numberOfPetsInHousehold.promotionalBox.icon)
                    ?.icon_code
                }
                heading={questions.numberOfPetsInHousehold.promotionalBox.heading}
                information={questions.numberOfPetsInHousehold.promotionalBox.information}
                button={{
                  buttonText:
                    questions.numberOfPetsInHousehold.promotionalBox.button_text,
                  screenReaderText:
                    questions.numberOfPetsInHousehold.promotionalBox
                      .button_screenreader_text,
                  onClick: () => {
                    updatePetsDetails(addItem(petsDetails, initialPet));
                    /* This use of setTimeout forces scrollAndFocusInput to be called after
                     * updatePetsDetails, so that we attempt to scroll to the newly added pet
                     * form section *after* the section has been rendered in the DOM.
                     *
                     * See https://stackoverflow.com/questions/779379/why-is-settimeoutfn-0-sometimes-useful for more
                     * details about this implementation.
                     */
                    setTimeout(() =>
                      scrollAndFocusInput(`petDetails[${petsDetails.length}]`)
                    );
                    trackTextButtonClick(
                      PageTitle.AboutYouAndYourPet,
                      'Add a pet - number of pets in household'
                    );
                  },
                }}
              />
            </GridItem>
          </Grid>
        )}
    </>
  );
};

export default AboutYouForm;
