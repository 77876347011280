import { LookupValidationErrors } from '@rsa-digital/evo-shared-components/hooks/useLookup';
import { graphql, useStaticQuery } from 'gatsby';

type CsPostcodeLookupErrorMessages = {
  csPetAboutYouPostcodeLookup: {
    postcode: {
      error_messages: {
        not_found: string;
        invalid_format: string;
        api_unavailable: string;
      };
    };
    address_select: {
      error_messages: {
        api_unavailable: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouPostcodeLookup {
      postcode {
        error_messages {
          not_found
          invalid_format
          api_unavailable
        }
      }
      address_select {
        error_messages {
          api_unavailable
        }
      }
    }
  }
`;

const usePostcodeLookupErrors = (): {
  postcode: LookupValidationErrors;
  select: LookupValidationErrors;
} => {
  const { postcode, address_select } = useStaticQuery<CsPostcodeLookupErrorMessages>(
    query
  ).csPetAboutYouPostcodeLookup;

  return {
    postcode: {
      notFound: postcode.error_messages.not_found,
      invalidFormat: postcode.error_messages.invalid_format,
      unavailable: postcode.error_messages.api_unavailable,
    },
    select: {
      notFound:
        // This case should never be reached but we hardcode a message just in case it happens.
        'Sorry, there was a problem with our system while verifying your address. Please enter your address manually.',
      invalidFormat: 'Check your postcode is correct.',
      unavailable: address_select.error_messages.api_unavailable,
    },
  };
};

export default usePostcodeLookupErrors;
