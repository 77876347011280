import {
  addYearsToDate,
  dateValueToUtcDate,
} from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import {
  applyRuleIf,
  dateValueLessThanOrEqualTo,
  dateValueValid,
  dateValueValidDay,
  dateValueValidMonth,
  dateValueValidYear,
  required,
  requiredDateValue,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationErrorAndWarningRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { assumptionsIncludeId } from 'businessLogic/aggregatorAssumptions';
import { graphql, useStaticQuery } from 'gatsby';
import useAssumptions from 'components/CheckYourDetails/AggregatorAssumptionsSection/assumptions';
import { isAfterMinValidDate } from 'helpers/ageHelpers';
import { hasGenderlessTitle } from 'helpers/genderHelpers';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useAssumptionsAgreement } from 'state/formData/assumptionsAgreement';
import { CustomerDetails } from 'state/formData/customerDetails';
import { usePetsDetails } from 'state/formData/petsDetails';
import { CsErrorsMissingOnly } from 'types/contentStack';
import emailValidationRules from '../../helpers/emailValidationRules';
import phoneValidationRules from '../../helpers/phoneValidationRules';

type CsAboutYouErrorMessages = {
  csPetAboutYouQuestions: {
    customer_title: CsErrorsMissingOnly;
    first_name: CsErrorsMissingOnly;
    last_name: CsErrorsMissingOnly;
    gender: CsErrorsMissingOnly;
    main_telephone: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
    email: {
      error_messages: {
        missing: string;
        too_short: string;
        invalid_format: string;
      };
    };
    date_of_birth: {
      error_messages: {
        missing: string;
        date_in_future: string;
        too_young: string;
        invalid_day: string;
        invalid_month: string;
        invalid_date: string;
        year_too_short: string;
      };
    };
    number_of_pets_in_household: {
      error_messages: {
        missing: string;
        less_than_pets_on_policy: string;
      };
    };
  };
  csPetAggregators: {
    assumptions: {
      one_pet_in_household: {
        warning: string;
      };
      two_pets_in_household: {
        warning: string;
      };
      three_pets_in_household: {
        warning: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetAboutYouQuestions {
      customer_title {
        error_messages {
          missing
        }
      }
      first_name {
        error_messages {
          missing
        }
      }
      last_name {
        error_messages {
          missing
        }
      }
      gender {
        error_messages {
          missing
        }
      }
      main_telephone {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
      email {
        error_messages {
          missing
          too_short
          invalid_format
        }
      }
      date_of_birth {
        error_messages {
          missing
          date_in_future
          too_young
          invalid_day
          invalid_month
          invalid_date
          year_too_short
        }
      }
      number_of_pets_in_household {
        error_messages {
          missing
          less_than_pets_on_policy
        }
      }
    }
    csPetAggregators {
      assumptions {
        one_pet_in_household {
          warning
        }
        two_pets_in_household {
          warning
        }
        three_pets_in_household {
          warning
        }
      }
    }
  }
`;

const useAboutYouRules = (): ValidationErrorAndWarningRules<CustomerDetails> => {
  const errorMessages = useStaticQuery<CsAboutYouErrorMessages>(query);
  const [petsDetails] = usePetsDetails();

  const assumptions = useAssumptions();
  const [assumptionsAgreement] = useAssumptionsAgreement();

  const validateAboutSection: boolean = petsDetails.some(
    ({ petType, petName, petIsEligible, petInGoodHealth, petLivesWithYou }) =>
      (petType === petType_DOG && petInGoodHealth && petIsEligible && petName) ||
      (petType === petType_CAT && petInGoodHealth && petLivesWithYou && petName)
  );

  return {
    errors: {
      customerTitle: validateIf(() => validateAboutSection, [
        required(
          errorMessages.csPetAboutYouQuestions.customer_title.error_messages.missing
        ),
      ]),
      customerFirstName: validateIf(() => validateAboutSection, [
        required(errorMessages.csPetAboutYouQuestions.first_name.error_messages.missing),
      ]),
      customerLastName: validateIf(() => validateAboutSection, [
        required(errorMessages.csPetAboutYouQuestions.last_name.error_messages.missing),
      ]),
      customerGender: validateIf(hasGenderlessTitle, [
        required(errorMessages.csPetAboutYouQuestions.gender.error_messages.missing),
      ]),
      customerTelephone: validateIf(
        () => validateAboutSection,
        phoneValidationRules(
          errorMessages.csPetAboutYouQuestions.main_telephone.error_messages
        )
      ),
      customerEmail: validateIf(
        () => validateAboutSection,
        emailValidationRules(errorMessages.csPetAboutYouQuestions.email.error_messages)
      ),
      customerDob: validateIf(() => validateAboutSection, [
        requiredDateValue(
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.missing
        ),
        dateValueValidDay(
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.invalid_day
        ),
        dateValueValidMonth(
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.invalid_month
        ),
        dateValueValid(
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.invalid_date
        ),
        dateValueValidYear(
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.year_too_short
        ),
        dateValueLessThanOrEqualTo(
          new Date(),
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.date_in_future
        ),
        dateValueLessThanOrEqualTo(
          addYearsToDate(new Date(), -18),
          errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages.too_young
        ),
        {
          validityCondition: (value) => isAfterMinValidDate(dateValueToUtcDate(value)),
          errorMessage:
            errorMessages.csPetAboutYouQuestions.date_of_birth.error_messages
              .invalid_date,
        },
      ]),
      numberOfPetsInHousehold: validateIf(() => validateAboutSection, [
        required(
          errorMessages.csPetAboutYouQuestions.number_of_pets_in_household.error_messages
            .missing
        ),
        {
          validityCondition: (value) => !!value && petsDetails.length <= value,
          errorMessage:
            errorMessages.csPetAboutYouQuestions.number_of_pets_in_household
              .error_messages.less_than_pets_on_policy,
        },
      ]),
    },
    warnings: {
      numberOfPetsInHousehold: [
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'one_pet_in_household'),
          {
            validityCondition: (value) => value !== 1,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.one_pet_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'two_pets_in_household'),
          {
            validityCondition: (value) => value !== 2,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.two_pets_in_household.warning,
          }
        ),
        applyRuleIf(
          () =>
            !assumptionsAgreement.assumptionsAgreed &&
            assumptionsIncludeId(assumptions, 'three_pets_in_household'),
          {
            validityCondition: (value) => value !== 3,
            errorMessage:
              errorMessages.csPetAggregators.assumptions.three_pets_in_household.warning,
          }
        ),
      ],
    },
  };
};

export default useAboutYouRules;
